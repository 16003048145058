import { HomePageContainer, StyledRow } from "@/components/shared/shared"
import LinkWithParams from "@/lib/common/LinkWithParams"
import { LinksType } from "@/lib/common/SiteLinks"
import { buttonMenuClick } from "@/lib/gtm"
import styled from "@emotion/styled"
import { ButtonBase, Collapse, Typography } from "@mui/material"
import { Fragment, useState } from "react"

const StyledNavBar = styled.div<{ $borderBottom: boolean }>`
  background-color: white;
  width: 100%;
`

const StyledContainer = styled(HomePageContainer)``

const StyledLinkButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => !["$selected"].includes(prop),
})<{ $selected?: boolean }>`
  display: flex;
  justify-content: center;
  cursor: pointer;
`

const StyledRowHeader = styled(StyledRow)`
  display: flex;
  align-items: center;
`

type HeaderSubCollapseProps = {
  subLinks: LinksType[] | null
  subCollapseRef: React.RefObject<HTMLDivElement>
}

const HeaderSubCollapse = ({
  subLinks,
  subCollapseRef,
}: HeaderSubCollapseProps): JSX.Element | null => {
  const [activeLink, setActiveLink] = useState<LinksType | null>(null)
  if (subLinks === null) {
    return null
  }
  return (
    <StyledNavBar $borderBottom={subLinks?.length > 0}>
      <StyledContainer ref={subCollapseRef} tabIndex={0}>
        <Collapse in={subLinks?.length > 0} timeout={"auto"} unmountOnExit>
          <StyledRowHeader $gap="32px" $notCenter $fullWidth $mt="10px" $mb="18px">
            {subLinks?.map((item, i) => (
              <Fragment key={i}>
                <LinkWithParams
                  href={item.link}
                  style={{ textDecoration: "none" }}
                  legacyBehavior
                  passHref
                >
                  <StyledLinkButton
                    onClick={() => {
                      setActiveLink(item)
                      buttonMenuClick(item.title)
                    }}
                    $selected={item === activeLink}
                  >
                    <Typography variant="body1">{item.title}</Typography>
                  </StyledLinkButton>
                </LinkWithParams>
              </Fragment>
            ))}
          </StyledRowHeader>
        </Collapse>
      </StyledContainer>
    </StyledNavBar>
  )
}

export default HeaderSubCollapse
