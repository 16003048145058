import { Desktop, Mobile } from "@/lib/common/MediaQueries"
import colors from "@/lib/theme/colors"
import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import Image from "next/image"

const StyledLayout = styled.div`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: ${colors.directDisable};
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 40px;
    padding: 128px 40px;
  }
`

const AdvantagesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  max-width: 1600px;
  ${(props) => props.theme.breakpoints.up("md")} {
    align-self: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
  }
  ${(props) => props.theme.breakpoints.up("xl")} {
    gap: 40px;
  }
`
const StyledAdvantage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  padding: 16px;
  text-align: center;
  background-color: white;
  border-radius: 8px;

  border: 1px solid ${colors.directVeryLightGray};
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 218px;
    max-width: 282px;
  }
  ${(props) => props.theme.breakpoints.up("xl")} {
    max-width: 370px;
  }
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
`

const advantages: { image: string; title: string; descr: string }[] = [
  {
    image: "/assets/fastoffer.svg",
    title: "ישיר וזמין",
    descr: "מספר רכבים מוגבל שאפשר למצוא רק באתר",
  },
  {
    image: "/assets/driving.svg",
    title: "ישיר ורלוונטי",
    descr: "דגמים ספציפיים בהצעות ייחודיות",
  },
  {
    image: "/assets/profile-circle.svg",
    title: "ישיר ופשוט",
    descr: "להשאיר פרטים או לשריין באמצעות מקדמה",
  },
  {
    image: "/assets/star.svg",
    title: "ישיר ובטוח",
    descr: "רכבים שמורים ישירות מהיבואן",
  },
]

export default function Advantages(): React.ReactElement {
  const title = "למה דירקט?"
  return (
    <StyledLayout>
      <Mobile>
        <Typography variant="h3">{title}</Typography>
      </Mobile>
      <Desktop centerHorizontally>
        <Typography variant="h2">{title}</Typography>
      </Desktop>
      <AdvantagesGrid>
        {advantages.slice(0, 4).map((el, i) => (
          <StyledAdvantage key={i}>
            <Desktop>
              <Image src={el.image} alt={el.title || ""} width={64} height={64} />
            </Desktop>
            <Mobile>
              <Image src={el.image} alt={el.title || ""} width={40} height={40} />
            </Mobile>
            <TextBox>
              <Typography variant="subtitle1">{el.title}</Typography>
              <Mobile>
                <Typography variant="body2">{el.descr}</Typography>
              </Mobile>
              <Desktop>
                <Typography variant="body1">{el.descr}</Typography>
              </Desktop>
            </TextBox>
          </StyledAdvantage>
        ))}
      </AdvantagesGrid>
    </StyledLayout>
  )
}
